<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <v-img src="@/assets/visiolens_logo.png"></v-img>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <!-- <base-item :item="profile" /> -->
      <v-row>
        <label>Usuario: {{ userData.name }} ({{ userData.roleDescription }}) </label>
      </v-row>
      <v-row>
        <label>Grupo: {{ userData.groupName }}</label>
      </v-row>
      <v-row>
        <label>Conta: {{ userData.accountName }}</label>
      </v-row>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item != null && item != undefined && item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else-if="item != null && item != undefined"
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <v-divider class="mb-2" />
    <v-row
      no-gutters
      style="height: 150px;"
    >
      <v-col cols="12" md="4" offset="4"
        align-self="end"
      >
        <v-chip >Versão {{ appVersion }}</v-chip>
      </v-col>
    </v-row>
    
  </v-navigation-drawer>
</template>

<script>
import { permissionsRules, roles } from '@/code/util/constants';
  // Utilities
  import {
    mapState,
  } from 'vuex'
  import { version } from '@/../package';
import localStorageUtil from '@/code/util/localStorageUtil';
import { EventBus } from '@/code/util/eventBus';

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      appVersion:version,
      rolesList: roles,
      computedItems: [],
      items: [
        {
          group: '/pages',
          icon: 'mdi-account-box-multiple-outline',
          title: 'Dashboard',
          children: [
            {
              icon: 'mdi-monitor-dashboard mdi-24px',
              title: 'Dashboard',
              to: 'home',
            },
            {
              icon: 'mdi-monitor-dashboard mdi-24px',
              title: 'Pedidos',
              to: 'dashboard-pedidos',
            },
          ]
        },
        {
          group: '/pages',
          icon: 'mdi-account-box-multiple-outline',
          title: 'Contas e Usuários',
          children: [
            {
              title: 'Grupos de contas',
              to: 'accountGroups',
              icon: 'mdi-office-building-marker-outline',
              permission: permissionsRules.Admin
            },
            {
              title: 'Contas',
              to: 'accounts',
              icon: 'mdi-office-building-marker-outline',
              permission: permissionsRules.ManageGroup
            },
            {
              title: 'Usuários',
              to: 'users',
              icon: 'mdi-account-circle',
              permission: permissionsRules.ManageAccount
            },
          ],
        },
        // {
        //   group: '/pages',
        //   icon: 'mdi-account-cash',
        //   title: 'Clientes',
        //   children: [
        //     {
        //       title: 'Clientes',
        //       to: 'clients',
        //       icon: 'mdi-card-account-details-outline',
        //     },
        //   ],
        // },
        // {
        //   group: '/pages',
        //   icon: 'mdi-barcode',
        //   title: 'Inventário',
        //   children: [
        //     {
        //       title: 'Produtos',
        //       to: 'products',
        //       icon: 'mdi-sunglasses',
        //     },

        //     {
        //       title: 'Fornecedores',
        //       to: 'suppliers',
        //       icon: 'mdi-dolly',
        //     },

        //     {
        //       title: 'Transportadoras',
        //       to: 'carriers',
        //       icon: 'mdi-van-utility',
        //     },
        //   ],
        // },
        // {
        //   group: '/pages',
        //   icon: 'mdi-cart-arrow-down',
        //   title: 'Pedidos',
        //   children: [
        //     {
        //       title: 'Listagem',
        //       to: 'orders',
        //       icon: 'mdi-clipboard-text-multiple-outline',
        //     },
        //     {
        //       title: 'Gerar Pedido',
        //       to: 'new_order',
        //       icon: 'mdi-cart-plus',
        //     },
        //   ],
        // },
        {
          group: '/pages',
          icon: 'mdi-iframe-outline',
          title: 'Tabelas',
          children: [
            {
              icon: 'mdi-link-box-variant',
              title: 'Tabelas De->Para',
              to: 'tables',
              permission: permissionsRules.ManageAccount
            },
            {
              icon: 'mdi-link-box-variant',
              title: 'Lista de tabelas',
              to: 'tables-list',
              permission: permissionsRules.ManageAccount
            },
          ],
        },
        {
          icon: 'mdi-google-assistant',
          title: 'Assistente',
          to: '/pages/assistent',
          permission: permissionsRules.ManageGroup
        },
      ],
      userData: {}
    }),

    beforeMount() {
      EventBus.$on("currentUserLoaded", this.currentUserChanged);
      this.loadCurrentUserData();
      this.updateMenuItems();
    },

    computed: {
      ...mapState(['barColor', 'barImage', 'loggedUser']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      profile () {
        return {
          avatar: true,
          title: this.$store.state.loggedUser,
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    methods: {
      mapItem (item) {
        let isVisible = this.userData && (
            item.permission == null
            || item.permission == undefined 
            || item.permission.some((p) => {
              return p == this.userData.role_enum;
            })
        );

        if (!isVisible) {
          return undefined;
        } else {
          let children = item.children ? item.children.map(this.mapItem) : undefined;

          if (!item.children || (children && children.length > 0)) {
            const a = {
              ...item,
              children: children ? children.filter((c) => c != null && c != undefined) : children,
              title: this.$t(item.title),
            }
            return a;
          } else {
            return undefined;
          }
        }
      },
      loadCurrentUserData: function() {
        let loggedUser = localStorageUtil.getCurrentUser();
        if (loggedUser) {
          let roleDesc = this.rolesList.filter((r) => r.role_enum == loggedUser.role_enum)
          this.userData = {
            name: loggedUser.name,
            email: loggedUser.email,
            login: loggedUser.login,
            role_enum: loggedUser.role_enum,
            accountName: loggedUser.account.account,
            groupName: loggedUser.group.account,
            roleDescription: roleDesc && roleDesc.length > 0 ? roleDesc[0].name : ''
          }
        }
      },
      currentUserChanged: function() {
        this.loadCurrentUserData();
        this.updateMenuItems();
      },
      updateMenuItems: function() {
        this.computedItems = this.items.map(this.mapItem);
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
